import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/theme/Layout'
import Header from '../../components/theme/Header'
import DiapersPage from "../../components/pages/DiapersPage";

const TermsOfServicePage = ({ data }) => (
  <Layout
    meta={data.defaultPage.meta}
    urls={data.defaultPage.urls}
    locale='fr'
  >
    <Header
      urls={data.defaultPage.urls}
      locale='fr'
      secondLogo
    />
    <DiapersPage locale='fr' />
  </Layout>
)

export const query = graphql`
query diapersFrPage {
  defaultPage(guid: { eq: "e7532e8e-02be-45bd-922b-0d2f47285bc5" }, locale: {eq:"fr"}) {
    title
    subtitle
    description
    urls {
      en
      fr
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default TermsOfServicePage
